<template>
  <header>
    <b-container>
      <b-row class="d-flex align-items-center">
        <b-col cols="6">
          <div class="app-logo">
            <b-img
              src="https://inallar.com.tr/template/inallar/images/inallar-logo.svg"
              fluid
            />
          </div>
        </b-col>
        <b-col cols="6" v-if="surveyData.brand_logo">
          <div class="brand-logo">
            <b-img :src="surveyData.brand_logo" fluid />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>
<script>
import { BRow, BCol, BImg } from "bootstrap-vue";
export default {
  name: "PageHeader",
  components: {
    BRow,
    BCol,
    BImg,
  },
  computed: {
    surveyData() {
      return this.$store.getters["surveyData"];
    },
  },
};
</script>
