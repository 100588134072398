<template>
  <div class="home-page-container mt-4">
    <error-404 />
  </div>
</template>

<script>
import Error404 from "@/views/Error404.vue";
export default {
  name: "Home",
  components: {
    Error404,
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      window.location.href = "https://inallar.com.tr";
    },
  },
};
</script>
