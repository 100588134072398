<template>
  <div class="border-light border p-4 text-center">
    <div v-if="dataSaveStatus.status === true || surveyData.status === true">
      <b-icon-check-circle scale="2" variant="success" />
      <div class="font-weight-bold mt-3">Anket Tamamlandı</div>
      <div>Ankete katılımınız için teşekkür ederiz.</div>
    </div>
    <div v-else>
      <b-icon-exclamation-triangle scale="2" variant="danger" />
      <div class="font-weight-bold text-danger mt-3">Anket Gönderilemedi</div>
      <div>Anketiniz gönderilemedi.<br />Lütfen tekrar deneyin.</div>
    </div>
  </div>
</template>

<script>
import { BIconCheckCircle, BIconExclamationTriangle } from "bootstrap-vue";
export default {
  name: "SurveyMessage",
  components: {
    BIconCheckCircle,
    BIconExclamationTriangle,
  },
  computed: {
    dataSaveStatus() {
      return this.$store.getters["dataSaveStatus"];
    },
    surveyData() {
      return this.$store.getters["surveyData"];
    },
  },
};
</script>
