<template>
  <div class="home-page-container mt-4">
    <page-loading v-if="dataLoading" />
    <template v-else>
      <template v-if="Array.isArray(surveyData)">
        <Error404 />
      </template>
      <template v-else>
        <div class="page-header">
          <h1>
            {{ surveyData.survey_type }}
          </h1>
          <h2>
            {{ surveyData.survey_type_sub_title }}
          </h2>
        </div>
        <survey-message
          v-if="surveyData.status === true || dataSaveStatus.status"
        />
        <survey-form
          v-else
          :survey-data="surveyData"
          :sendSurvey="sendSurvey"
        />
      </template>
    </template>
  </div>
</template>
<script>
import SurveyForm from "@/components/SurveyForm.vue";
import SurveyMessage from "@/components/SurveyMessage.vue";
import PageLoading from "@/components/PageLoading.vue";
import Error404 from "@/views/Error404.vue";

export default {
  name: "Survey",
  components: {
    Error404,
    SurveyForm,
    SurveyMessage,
    PageLoading,
  },
  computed: {
    surveyData() {
      return this.$store.getters["surveyData"];
    },
    dataLoading() {
      return this.$store.getters["loadingData"];
    },
    dataSaveStatus() {
      return this.$store.getters["dataSaveStatus"];
    },
  },
  created() {
    this.getSurveyData();
  },
  methods: {
    getSurveyData() {
      this.$store.dispatch("getSurvey", {
        survey_code: this.$route.params.survey_code,
      });
    },
    sendSurvey() {
      this.$store.dispatch("sendSurvey", {
        survey_data: this.surveyData.survey_data,
        id_com_survey: this.surveyData.id,
        customer_request: this.surveyData.customer_request,
        survey_code: this.$route.params.survey_code,
      });
    },
  },
};
</script>
