import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    surveyData: {
      id: null,
      survey_data: {
        initial: null,
        status: null,
        content: null,
        brand_logo: null,
        id_com_interview_type: null,
        id_com_interview_subject: null,
        id_com_meet: null,
        id_com_cars: null,
        id_com_customer: null,
        id_com_brand: null,
        id_com_user: null,
        id_com_user1: null,
        idate: null,
        itime: null,
        chasis: null,
        pollcode: null,
        interview_poll: {
          brand: null,
          subject: null,
          pollcode: null,
          poll: [],
        },
      },
      survey_type: null,
      survey_type_sub_title: null,
      company_name: null,
      customer: null,
      customer_request: null,
    },
    dataLoading: false,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  mutations: {
    SET_SURVEY_DATA(state, data) {
      state.surveyData = data;
    },
    SET_LOADING_DATA(state, data) {
      state.dataLoading = data;
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data;
    },
  },
  actions: {
    getSurvey({ commit }, params) {
      commit("SET_LOADING_DATA", true);
      axios
        .post("surveys", params)
        .then((response) => {
          commit("SET_SURVEY_DATA", response.data.data);
          commit("SET_LOADING_DATA", false);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendSurvey({ commit }, params) {
      commit("SET_LOADING_DATA", true);
      axios.post("surveys/closeSurvey", params).then((response) => {
        commit("SET_LOADING_DATA", false);
        commit("SET_DATA_SAVE", response.data);
      });
    },
  },
  getters: {
    surveyData(state) {
      return state.surveyData;
    },
    loadingData(state) {
      return state.dataLoading;
    },
    dataSaveStatus(state) {
      return state.dataSave;
    },
  },
});
