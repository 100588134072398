<template>
  <div class="border-light border p-4 mt-4 text-center">
    <h2 class="font-weight-bold">404</h2>
    <div class="text-body">Aradığınız sayfa bulunamadı.</div>
    <div class="mt-4">
      <b-button variant="primary" href="https://inallar.com.tr">
        Anasayfa
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  name: "Error404",
  components: {
    BButton,
  },
};
</script>
