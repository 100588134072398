<template>
  <div id="app">
    <page-header />
    <b-container>
      <b-row>
        <b-col cols="12">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
    <page-footer />
  </div>
</template>
<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import PageHeader from "@/components/common/PageHeader.vue";
import PageFooter from "@/components/common/PageFooter.vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    PageHeader,
    PageFooter,
  },
};
</script>
