<template>
  <div class="p-5 d-flex align-items-center justify-content-center">
    <div class="text-center">
      <b-spinner variant="light" type="grow" />
      <div class="mt-2 small text-light">Lütfen bekleyiniz</div>
    </div>
  </div>
</template>
<script>
import { BSpinner } from "bootstrap-vue";
export default {
  name: "PageLoading",
  components: {
    BSpinner,
  },
};
</script>
