<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in surveyData.survey_data.interview_poll.poll"
        :key="index"
      >
        <b-form-group :label="item.questions">
          <b-form-rating
            v-model="item.selected"
            variant="warning"
            :stars="item.options.length"
            no-border
            size="lg"
            class="p-0"
          />
        </b-form-group>
      </b-list-group-item>
    </b-list-group>
    <div class="customer_request">
      <b-form-group label="Mesajınız" label-for="customer_request">
        <b-form-textarea
          id="customer_request"
          v-model="surveyData.customer_request"
          placeholder="Eklemek istedikleriniz"
        />
      </b-form-group>
    </div>
    <div class="button-container pb-5">
      <b-button
        variant="primary"
        class="mb-5"
        @click="sendSurvey"
        size="lg"
        block
        >ANKETİ GÖNDER</b-button
      >
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BFormGroup,
  BFormRating,
  BFormTextarea,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

export default {
  name: "SurveyForm",
  components: {
    BFormTextarea,
    BListGroupItem,
    BListGroup,
    BFormRating,
    BButton,
    BFormGroup,
  },
  props: {
    surveyData: {
      type: Object,
      required: true,
    },
    sendSurvey: {
      type: Function,
      required: true,
    },
  },
};
</script>
